<template>
  <v-container class="print-page">
    <div
      v-for="print in getPrintTimesheet"
      v-show="print.user.workdays.length > 0"
      :key="print.user.id"
      class="pagebreak"
    >
      <table class="table-header">
        <tr>
          <workday-header
            :show-advertence="false"
            :driver="print.user"
            :print="true"
          />
        </tr>
      </table>

      <v-row class="timesheet-infos">
        <div class="mr-8">
          <strong>{{ $t('components.header.timesheets.period') }}:</strong>
          {{ dayOfWeek(getSpecificTimesheet.date_init, 'dd/MM/yyyy') }}
          até
          {{ dayOfWeek(getSpecificTimesheet.date_end, 'dd/MM/yyyy') }}
        </div>
        <div class="mr-8">
          <strong>{{ $t('components.header.timesheets.business_unit') }}:</strong>
          {{ getSpecificTimesheet.business_unit_name }}
        </div>
        <div>
          <strong>{{ $t('components.header.timesheets.branch_office') }}:</strong>
          {{ getSpecificTimesheet.branch_name }}
        </div>
      </v-row>

      <v-row class="row-spacing">
        <mirror-show-list
          :totalizer="print.user"
          :print="true"
          :workdays="print.user.workdays"
          :overnight-missing-total="print.user.overnight_missing_total"
          :meal-missing-total="print.user.meal_missing_total"
          :drs-suppressed-total="print.user.dsr_suppressed_total"
          :quantity-drs-suppressed="print.user.quantity_dsr_suppressed"
        />
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WorkdayHeader from '@/components/headers/WorkdayHeader.vue';
import MirrorShowList from '@/components/lists/MirrorShowList.vue';
import formats from '@/mixins/formats';

export default {
  name: 'Print',
  components: {
    WorkdayHeader,
    MirrorShowList,
  },
  mixins: [formats],
  computed: {
    ...mapGetters([
      'getPrintTimesheet',
      'getSpecificTimesheet',
    ]),
  },
  mounted() {
    this.printTimesheet({ id: this.$route.params.timesheetId });
    this.showTimesheet({ id: this.$route.params.timesheetId });
  },
  methods: {
    ...mapActions([
      'printTimesheet',
      'showTimesheet',
    ]),
    text(label) {
      return this.$t(`components.print.${label}`);
    },
    formatDate(dateParam) {
      return this.formatDateISO(dateParam);
    },
  },
};
</script>

<style lang="scss" scoped>
  .print-page {

    .table-header {
      width: 100%;
      margin-bottom: 10px;

      tr {
        width: 100%;
      }
    }

    .timesheet-infos{
      font-family: 'Acumin-Pro-Regular';
      font-size: 14px;
    }

    .row-spacing {
      margin-bottom: 30px;
    }
  }

  @media print {
    .print-page {
      display: block;
      .pagebreak {
        page-break-before: always;
        page-break-inside: avoid;

        .timesheet-infos{
          font-size: 10px;
        }

        .row-spacing {
          margin-bottom: 10px;
        }
      }
    }
  }

  @page {
    size: landscape;
  }
</style>
<style lang="scss" src="@/assets/scss/components/lists/point_mirror.scss" scoped>
